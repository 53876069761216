<template>
  <div>
    <div class = "FlexRow" v-loading = "pageloading">
      <el-card shadow = "never" style="width:70%">
        <div class = "FlexRow" style="margin-top:3px">
          <div style="width:50px;height:50px">
            <img :src="groupImg" style="width:100%;height:100%" alt="">
          </div>
          <div style="margin-left:10px">
            <div class = "theFontHidden" style="width:200px">
              {{groupMsg.GroupName}}
            </div>
            <div class = "FlexRow" style="margin-top:15px;color:#999999">
              <div>群主:{{groupMsg.EmployeeName}}</div>
              <div style="width:1px;height:20px;background:rgba(180,180,180,0.5);margin:0px 30px"></div>
              <div>群内人数:{{groupMsg.GroupUserCount}}</div>
              <div style="width:1px;height:20px;background:rgba(180,180,180,0.5);margin:0px 30px"></div>
              <div>群内客户数:{{groupMsg.GroupCustomerCount}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow = "never" style="width:30%;margin-left:10px">
        <div style="font-size:16px;font-weight:600">群公告</div>
        <el-popover placement="top-start" width="400" trigger="hover" :content="groupMsg.Notice" v-if="groupMsg.Notice">
          <div style="width:100%;margin-top:15px" slot="reference" class = "theFontHidden">{{groupMsg.Notice}}</div>
        </el-popover>
		<div class="no-notice" v-else>未配置</div>
      </el-card>
    </div>
    <el-card shadow = "never" style="margin-top:15px">
      <div style="margin:15px 0px;display:flex;flex-direction:row">
        <div style="font-size:14px;line-height:37px;margin-right:15px">时间:</div>
        <el-date-picker
        popper-class = "pickeroptClass"
        @change="changedata"
          v-model="timeValue"
          type="daterange"
          align="right"
          unlink-panels
		  :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <el-radio-group v-model="tabPosition" style="margin-top:20px;" @change="radioChange">
        <el-radio-button label="0">群内总人数</el-radio-button>
        <el-radio-button label="1">群内客户数</el-radio-button>
        <el-radio-button label="2">入群客户数</el-radio-button>
        <el-radio-button label="3">退群客户数</el-radio-button>
      </el-radio-group>
      <line-chart v-loading = 'chartloading' :info="data" v-if="data.length" :tooltip='tooltip' :id="'Chart2'" style="width:100%;height:300px"></line-chart>
    </el-card>
    <el-card shadow = "never" style="margin-top:15px">
      <el-radio-group v-model="groupClassity" style="margin-top:20px;" @change="tableRadioChange">
        <el-radio-button label="0">群内微信客户</el-radio-button>
        <el-radio-button label="1">群内企业客户</el-radio-button>
      </el-radio-group>
      <div>
        <div style="margin-top:15px">
          <el-table :data="tableList1" v-loading = "tableloading1">
            <el-table-column prop="" label="客户">
              <template slot-scope="scope">
                    <div class="FlexRow" v-if="groupClassity == 0">
                      <div style="width:50px;height:50px">
                        <img :src="scope.row.HeadUrl?scope.row.HeadUrl: defaultImg" style="width:100%;height:100%" alt=""/>
                      </div>
                      <div style="margin-left:10px">
                        <div style="width:150px" class="theFontHidden">{{scope.row.NickName}}</div>
                        <div style="width:150px" class="theFontHidden">{{scope.row.Phone}}</div>
                      </div>
                    </div>
                    <div class="FlexRow" v-if="groupClassity == 1">
                      <div style="width:50px;height:50px">
                        <img :src="scope.row.HeadUrl?scope.row.HeadUrl: defaultImg" style="width:100%;height:100%" alt=""/>
                      </div>
                      <div style="margin-left:10px">
                        <div style="width:150px;height:50%" class="theFontHidden">{{scope.row.NickName}}</div>
                        <div>
                          <div style="width:150px;color:#409eff" class="theFontHidden" v-if="scope.row.IsOwner == 'True'">@群主</div>
                          <div style="width:150px;color:#409eff" class="theFontHidden" v-else-if="scope.row.IsOwner=='False'&&scope.row.Type==1">@本企业成员</div>
                          <div style="width:150px;color:rgb(236,184,106)" class="theFontHidden" v-else-if="scope.row.IsOwner=='False'&&scope.row.Type==3">@{{scope.row.CropName}}</div>
                        </div>
                      </div>
                    </div>
               </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="入群时间"></el-table-column>
            <el-table-column prop="JoinSceneValue" label="入群方式"></el-table-column>
            <el-table-column prop="InvitorName" label="邀请员工">
              <template slot="header">
                  <div class = "FlexRow">
                    <div>邀请员工</div>
                      <el-tooltip class="item" effect="dark" content="仅本企业成员邀请入群才可显示邀请员工" placement="top">
                        <i class="el-icon-warning-outline" style="font-size:18px;margin:2px 0px 0px 5px"></i>
                      |</el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div v-if="scope.row.InvitorName">
                    {{scope.row.InvitorName}}
                  </div>
                  <div v-else>
                    --
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="50px" v-if="groupClassity == 0">
              <template slot-scope="scope">
                <el-button type="text" @click="tableDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total1">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total1">
          </el-pagination>
        </div>
      </div>
      <!-- <div v-if="groupClassity == 1">
        <div style="margin-top:15px">
          <el-table :data="tableList2" v-loading = "tableloading2">
            <el-table-column prop="" label="企业客户" width="400px">
              <template slot-scope="scpoe">
                    <div class="FlexRow">
                      <div style="width:50px;height:50px">
                        <img :src="1 == 2 ? imgApi + '' : defaultImg" style="width:100%;height:100%" alt=""/>
                      </div>
                      <div style="margin-left:10px">
                        <div style="width:150px" class="theFontHidden">1222222222222222222222222222222222</div>
                        <div style="width:150px" class="theFontHidden">111111111111111111111111111111111111</div>
                      </div>
                    </div>
                  </template>
            </el-table-column>
            <el-table-column prop="" label="入群时间"></el-table-column>
            <el-table-column prop="" label="入群方式"></el-table-column>
            <el-table-column prop="" label="邀请员工">
              <template slot="header">
                  <div class = "FlexRow">
                    <div>邀请员工</div>
                      <el-tooltip class="item" effect="dark" content="仅本企业成员邀请入群才可显示邀请员工" placement="top">
                        <i class="el-icon-warning-outline" style="font-size:18px;margin:2px 0px 0px 5px"></i>
                      |</el-tooltip>
                  </div>
                </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total2">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
      </div> -->
    </el-card>
  </div>
</template>

<script>
import lineChart from '@/components/Charts/qyWechatChart'
import config from "@/config/index";
import{
  qyweixinrealgroupdetail,
  groupcustomerpagelist,
  customergroupdetailstatis
}from "@/api/TurnTomySelf"
export default {
  components:{
    lineChart,
  },
  data () {
    return {
      goUrls: config.GO_URL,
      pageloading:false,
      defaultImg:"https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png",
      groupImg:'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
      groupMsg:{},
      timeValue:[],
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts:[{
            text: '今日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1));
              // console.log(start.getDay())
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '上周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1 + 7));
              end.setTime(end.getTime() - 3600 * 1000 * 24 * end.getDay());
              // console.log(start.getDay())
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '过去7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '过去30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      tabPosition:0,
      //折线图
      data:[],
      chartloading:false,
      tooltip:'群内总人数',
      groupClassity:0,
      tableList1:[],
      currentPage1:1,
      sizepage1:20,
      total1:null,
      tableloading1:false,


      tableList2:[],
      currentPage2:1,
      sizepage2:20,
      total2:null,
      tableloading2:false,
    }
  },
  created () {
    this.getToday()
    this.getqyweixinrealgroupdetail()
    this.getgroupcustomerpagelist()
    this.getcustomergroupdetailstatis()
    // console.log(this.$route.query.Id)
  },
  methods: {
    getToday(){
      let end = new Date()
      let start = new Date()
      this.timeValue = [end,start]
    },
    tableDetail(e){
      // console.log(e)
      let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
			let url = this.goUrls + headsUrls + '/Customer/CustomerDetail?Id='+e.Id
			window.open(url)
    },
    handleSizeChange1(e){
      this.currentPage1 = 1,
      this.sizepage1 = e
      this.getgroupcustomerpagelist()
    },
    handleCurrentChange1(e){
      this.currentPage1 = e
      this.getgroupcustomerpagelist()
    },
    // handleSizeChange2(e){
    //   this.currentPage2 = 1,
    //   this.sizepage2 = e
    // },
    // handleCurrentChange2(e){
    //   this.currentPage2 = e
    // },
    radioChange(e){
      if(e == 0){
        this.tooltip = '群内总人数'
      }else if(e == 1){
        this.tooltip = '群内客户数'
      }else if(e == 2){
        this.tooltip = '入群客户数'
      }else if(e == 3){
        this.tooltip = '退群客户数'
      }
      this.getcustomergroupdetailstatis()
    },
    //切换群内微信客户he群内企业客户
    tableRadioChange(e){
      this.groupClassity = e
      this.currentPage1 = 1
      this.getgroupcustomerpagelist()
    },
    changedata(){
      this.getcustomergroupdetailstatis()
    },
    changetime(time){
      if(time){
        let year = time.getFullYear()
        let month = time.getMonth()+1>=10?time.getMonth()+1:'0'+(time.getMonth()+1)
        let date = time.getDate()>=10?time.getDate():'0'+time.getDate()
        // console.log((year+'-'+month+'-'+date))
        return (year+'-'+month+'-'+date)
      }
    },
    //获取表格数据
    async getgroupcustomerpagelist(){
      this.tableloading1 = true
      let data = {
        CustomerType:this.groupClassity,
        Id:this.$route.query.Id,
        Skip:(this.currentPage1-1)*this.sizepage1,
        Take:this.sizepage1
      }
      let result = await groupcustomerpagelist(data)
      if(result.IsSuccess){
        // console.log(result.Result)
        this.tableList1 = result.Result.Results
        this.total1 = result.Result.Total
      }
      this.tableloading1 = false
    },
    //头部群信息
    async getqyweixinrealgroupdetail(){
      this.pageloading = true
      let data = {
        Id:this.$route.query.Id
      }
      let result = await qyweixinrealgroupdetail(data)
      if(result.IsSuccess){
        // console.log(result.Result)
        this.groupMsg = result.Result
      }
      this.pageloading = false
    },
    //折线图
    async getcustomergroupdetailstatis(){
      this.chartloading = true
      let data = {
        QyWeixinRealGroupId:this.$route.query.Id,
        StartTime:this.changetime(this.timeValue[0]),
        EndTime:this.changetime(this.timeValue[1]),
      }
      // console.log(this.timeValue)
      let result = await customergroupdetailstatis(data)
      if(result.IsSuccess){
        // console.log(result.Result)
        this.data = []
        result.Result.map((v)=>{
          let msg = {
            value:'',
            label:v.StatisDate.substring(5,10),
          }
          // console.log(v.StatisDate.substring(5,10))
          if(this.tabPosition == 0){
            msg.value = v.QyWeixinGroupAllMemberCount
          }else if(this.tabPosition == 1){
            msg.value = v.QyWeixinGroupCustomerCount
          }else if(this.tabPosition == 2){
            msg.value = v.QyWeixinGroupAddCustomerCount
          }else if(this.tabPosition == 3){
            msg.value = v.QyWeixinGroupWithDrawustomerCount
          }
          this.data.push(msg)
          return
        })
      }
      this.chartloading = false
    },
  }
}
</script>

<style scoped>
*,html,body{
  font-size:14px
}
  .FlexRow {
    display: flex;
    flex-direction: row;
  }
  .theFontHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .no-notice{
	  font-size: 14px;
	  color: #999;
	  padding: 20px 0;
  }
</style>
<style>
.pickeroptClass.el-picker-panel{  
      left:298px !important
  }
  .pickeroptClass.el-popper .popper__arrow::after{
    left:-250px !important
  }
</style>