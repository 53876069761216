<template>
	<div :id="id" :class="className" :style="{ height: height, width: width }" :info="info" :tooltip="tooltip"></div>
</template>

<script>
	import echarts from "echarts";
	import resize from "./mixins/resize";
	
	export default {
		mixins: [resize],
		props: {
			width: {
				type: String,
				default: "100%",
			},
			height: {
				type: String,
				default: "100%",
			},
			tooltip: {
				type: String,
				default: "人数",
			},
			className: {
				type: String,
				default: "chart",
			},
			id: {
				type: String,
				default: "chart",
			},
			info: {
				type: Array,
				default: () => {
					return {};
				},
			},
		},
		watch: {
			info(val) {
				this.datainfo = val;
				this.initChart();
			},
			tooltip(val) {
				this.initChart();
			},
		},
		mounted() {
			this.$nextTick(()=>{
				this.myChart = echarts.init(document.getElementById(this.id));
			})

			this.datainfo = this.info;
			this.initChart();
			
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		data() {
			return {
				chart: null,
				datainfo: [],
				myChart: null,
			};
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			initChart() {
				let option = {
					xAxis: {
						type: "category",
						data: this.datainfo.map((v) => {
							return v.label;
						}),
					},
					yAxis: {
						minInterval: 1,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					grid: {
						left: '1%',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					series: [{
						data: this.datainfo.map((v) => {
							return v.value;
						}),
						type: "line",
						symbol: 'circle',
						symbolSize: 10, //折线点的大小
						itemStyle: {
							color: "#409EFF",
							background: "#409EFF",
						},
						lineStyle: {
							color: "#409EFF",
						},
					}, ],
					tooltip: {
						trigger: "item",
						formatter: `${this.tooltip}:{c}`,
						//   backgroundColor:'#ffffff',
						//   textStyle: {
						//       color: '#000000'
						//   },
						//   axisPointer:{
						//     type : 'shadow',
						//     shadowStyle : {              // 阴影指示器样式设置
						//     width: 'auto',         // 阴影大小
						//     color: 'rgba(150,150,150,0.3)'  // 阴影颜色
						// }
						//   }
					},
				};
				this.$nextTick(() => {
					this.myChart.setOption(option);
				});
			},
		},
	};
</script>

<style></style>
